(function($)
{
  "use strict";

  if (Lum === undefined)
  {
    throw new Error("Missing Lum core");
  }

  Lum.registerNamespace('Lores.Core.Factory');

  let isOk = Lores.Core.isOk = function (data, childName, isArray=true)
  {
    return (typeof data === 'object' && data.success &&
      typeof data[childName] === 'object' &&
      (
        !isArray || typeof data[childName].length === 'number' 
      )
    );
  }

  Lores.Core.Factory.setIfOk = function (privateStorage)
  {
    function setIfOk (data, childName, isArray=true)
    {
      if (isOk(data, childName, isArray))
      {
        privateStorage[childName] = data[childName];
      }
    }    
    return setIfOk;
  }

  Lores.Core.registerTableToggle = function ()
  {
    $('table > caption').on('click', function (e)
    {
      let table = $(this).parent();
      let contents = table.find('thead,tbody,tfoot');
      contents.toggle();
    });    
  }

})(jQuery);